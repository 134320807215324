<template>
  <div class="js-common-wrap js-modify-personInfo">
    <div class="js-common-head">
      <label>个人信息修改</label>
        <el-button type="primary" size="small" @click="save">保存</el-button>
    </div>
    <div class="js-common-content">
      <ul class="form-box">
        <li class="common-tip">
          <label><i>*</i>姓名：</label>
          <el-input v-model="userInfo.realName" placeholder="请输入……" size="small"></el-input>
        </li>
        <li>
          <label>手机号：</label>
          <el-input v-model="userInfo.mobile" placeholder="请输入……" size="small"></el-input>
        </li>
        <li>
          <label>介绍：</label>
          <el-input v-model="userInfo.description" type="textarea" :rows="6" placeholder="请输入……" size="small"></el-input>
        </li>
        <li>
          <label>头像：</label>
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :auto-upload="false" 
            :on-change="handleHttpRequest">
            <img class="img" v-if="userInfo.fullAvatar" :src="userInfo.fullAvatar">
            <div v-else class="icon">
              <img src="@/assets/images/13.png" alt="">
              <p>上传图片</p>
            </div>
          </el-upload>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Jiashi10Modifypersoninfo',
  data() {
    return {
      id: '',
      userInfo: {
        realName: '',
        mobile: '',
        description: '',
        fullAvatar: ''
      },
      action: ''
    }
  },
  computed:{
    ...mapGetters(['getUserInfo'])
  },
  mounted() {
    this.id = this.getUserInfo.id
    this.getUserInfoById()
  },
  methods: {
    getUserInfoById(){
      this.$axios({
        method: 'get',
        url: `api/csp/mgr/v1/user/${this.id}`,
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.userInfo = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 获取上传文件地址
    getUploadDir(){
      return new Promise((resolve,reject)=>{
        this.$axios({
          method: 'get',
          url: 'api/csp/cos/v1/url/upload',
          params: {
            dirType: 'upload',
            suffix: 'jpeg'
          }
        }).then((response)=>{
          let data = response.data
          if(!data.status){
            this.action = data.data
          }else{
            this.$message({
              type: 'error',
              message: data.msg,
              duration: 1000
            })
          }
          resolve()
        }).catch(()=>{
          reject()
        })
      })
    },
    async handleHttpRequest(file){
      await this.getUploadDir()
      var xhr = new XMLHttpRequest()
      xhr.open('PUT', this.action.url, true)
      xhr.onload = (e) => {
        this.userInfo.avatar = this.action.file
        this.userInfo.fullAvatar = this.action.url.split('?')[0]
        this.$forceUpdate()
      }
      xhr.onerror = (e) => {}
      xhr.send(file.raw)
    },
    // 保存
    save(){
      this.$axios({
        method: 'put',
        url: `api/csp/mgr/v1/user/person`,
        data:{
          data:{
            realName: this.userInfo.realName,
            mobile: this.userInfo.mobile,
            description: this.userInfo.description,
            avatar: this.action.file
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.$message({
            type: 'success',
            message: '个人信息修改成功，请重新登录！',
            duration: 1000
          })
          this.$router.push({
            path: '/'
          })
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/personCenter.scss';
</style>