var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap js-modify-personInfo" }, [
    _c(
      "div",
      { staticClass: "js-common-head" },
      [
        _c("label", [_vm._v("个人信息修改")]),
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "js-common-content" }, [
      _c("ul", { staticClass: "form-box" }, [
        _c(
          "li",
          { staticClass: "common-tip" },
          [
            _vm._m(0),
            _c("el-input", {
              attrs: { placeholder: "请输入……", size: "small" },
              model: {
                value: _vm.userInfo.realName,
                callback: function ($$v) {
                  _vm.$set(_vm.userInfo, "realName", $$v)
                },
                expression: "userInfo.realName",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("手机号：")]),
            _c("el-input", {
              attrs: { placeholder: "请输入……", size: "small" },
              model: {
                value: _vm.userInfo.mobile,
                callback: function ($$v) {
                  _vm.$set(_vm.userInfo, "mobile", $$v)
                },
                expression: "userInfo.mobile",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("介绍：")]),
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 6,
                placeholder: "请输入……",
                size: "small",
              },
              model: {
                value: _vm.userInfo.description,
                callback: function ($$v) {
                  _vm.$set(_vm.userInfo, "description", $$v)
                },
                expression: "userInfo.description",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("label", [_vm._v("头像：")]),
            _c(
              "el-upload",
              {
                staticClass: "avatar-uploader",
                attrs: {
                  action: "#",
                  "show-file-list": false,
                  "auto-upload": false,
                  "on-change": _vm.handleHttpRequest,
                },
              },
              [
                _vm.userInfo.fullAvatar
                  ? _c("img", {
                      staticClass: "img",
                      attrs: { src: _vm.userInfo.fullAvatar },
                    })
                  : _c("div", { staticClass: "icon" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/13.png"),
                          alt: "",
                        },
                      }),
                      _c("p", [_vm._v("上传图片")]),
                    ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [_c("i", [_vm._v("*")]), _vm._v("姓名：")])
  },
]
render._withStripped = true

export { render, staticRenderFns }